import { FC } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { useFormContext, Controller } from 'react-hook-form';

import DatePicker from 'react-datepicker';
import { ErrorMessage } from '@hookform/error-message';

import Select from 'react-dropdown-select';

import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface Option {
  name: string;
}

interface UserDetailsModalProps {
  show: boolean;
  handleClose: () => void;
  isEnd?: React.Dispatch<React.SetStateAction<boolean>>;
  howDidYouHearAboutUsOptions?: Option[];
  minimalAge?: number;
}

export const UserDetailsModal: FC<UserDetailsModalProps> = ({
  show,
  handleClose,
  isEnd,
  howDidYouHearAboutUsOptions = [],
  minimalAge,
}) => {
  const {
    control,
    register,
    trigger,
    formState: { errors },
  } = useFormContext();

  const onClickSubmit = async () => {
    const validated = await trigger([
      'firstName',
      'lastName',
      'email',
      'phoneNumber',
      'birthDate',
      'licensePlate',
    ]);

    // TODO: submit user details
    if (isEnd && validated) {
      isEnd(true);
      handleClose();
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="post-editor-modal modal-200w"
      backdrop="static"
      id="guest-portal"
    >
      <Modal.Header className="guest-modal-header ">
        <Modal.Title className="editor-title">
          <PersonCircle size={25} /> {'  '}
          Enter your Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="guest-modal-header">
        <Form>
          <Row>
            <Col>
              <Form.Label className="editor-title-label">First Name</Form.Label>
              <Form.Control
                className="editor-control guest-inputs"
                placeholder="Enter your First name..."
                {...register('firstName', { required: 'This is required.' })}
              />
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Last Name</Form.Label>
              <Form.Control
                className="editor-control guest-inputs"
                placeholder="Enter your Last name..."
                {...register('lastName', { required: 'This is required.' })}
              />
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>

          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Email Address</Form.Label>
              <Form.Control
                className="editor-control guest-inputs"
                placeholder="Enter your Email..."
                {...register('email', {
                  required: 'This is required.',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>
          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">Phone number</Form.Label>

              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: 'This is required.',
                  validate: {
                    hasMoreThanFourDigits: value => {
                      const digits = value.replace(/\D/g, '');
                      return digits.length > 4 || 'Enter a valid number.';
                    },
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    country="us"
                    value={value}
                    onChange={phoneValue => {
                      onChange(
                        phoneValue.startsWith('+') ? phoneValue : `+${phoneValue}`,
                      );
                    }}
                    inputStyle={{ width: '100%' }}
                    countryCodeEditable={false}
                  />
                )}
              />

              <ErrorMessage
                errors={errors}
                name="phoneNumber"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>

          <Row className="mt-16px">
            <Col xs={12}>
              <Form.Label className="editor-title-label">Date of Birth</Form.Label>
            </Col>
            <Col xs={12}>
              <div className="datePicker">
                <Controller
                  control={control}
                  name="birthDate"
                  rules={{
                    required: 'This is required.',
                    validate: value => {
                      if (!value) return 'This is required.';

                      const birthDate = new Date(value);
                      const today = new Date();
                      const age = today.getFullYear() - birthDate.getFullYear();
                      const validAge = minimalAge || 21;
                      const monthDiff = today.getMonth() - birthDate.getMonth();
                      const dayDiff = today.getDate() - birthDate.getDate();

                      if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                        return age - 1 >= validAge
                          ? true
                          : `You must be at least ${validAge || 21} years old.`;
                      }

                      return age >= validAge
                        ? true
                        : `You must be at least ${validAge || 21} years old.`;
                    },
                  }}
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      wrapperClassName="datePicker"
                      placeholderText="Select date"
                      onChange={date => field.onChange(date)}
                      selected={field.value}
                      customInput={
                        <Form.Control
                          className="editor-control guest-inputs"
                          inputMode="none"
                        />
                      }
                      onYearChange={date => field.onChange(date)}
                      onMonthChange={date => field.onChange(date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      maxDate={new Date()}
                      dateFormat="MM/dd/YYYY"
                    />
                  )}
                />
              </div>
              <ErrorMessage
                errors={errors}
                name="birthDate"
                render={({ message }) => <span className="error-text">{message}</span>}
              />
            </Col>
          </Row>

          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">License Plate</Form.Label>
              <Form.Control
                className="editor-control guest-inputs"
                placeholder="Enter your License plate..."
                {...register('licensePlate')}
              />
              <ErrorMessage
                errors={errors}
                render={({ message }) => <span className="error-text">{message}</span>}
                name="licensePlate"
              />
            </Col>
          </Row>

          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">
                Estimated Time Of Arrival
              </Form.Label>
              <Form.Control
                className="editor-control guest-inputs"
                placeholder="Enter your Estimated Time Of Arrival..."
                {...register('estimatedTimeOfArrival')}
              />
            </Col>
          </Row>

          <Row className="mt-16px">
            <Col>
              <Form.Label className="editor-title-label">
                How did you hear about us?
              </Form.Label>
              <Controller
                name="howDidYouHearAboutUs"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={howDidYouHearAboutUsOptions}
                    className="editor-control guest-inputs"
                    placeholder="Select an option..."
                    onChange={([selected]) => field.onChange(selected.name)}
                    values={field.value ? [{ name: field.value }] : []}
                    valueField="name"
                    labelField="name"
                  />
                )}
              />
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Container className="guest-modal-footer ">
        <Button className="cancel-button no-border-radius" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          className="save-button no-border-radius"
          style={{ marginLeft: '10px' }}
          onClick={onClickSubmit}
        >
          Continue
        </Button>
      </Container>
    </Modal>
  );
};
